import React, { useState } from "react";

import { graphql, useStaticQuery } from "gatsby";
import { Banner } from "src/components/Banner";
import { Button } from "src/components/Button";
import { Container } from "src/components/Container";
import { PageWrapper } from "src/components/PageWrapper";
import { Typography } from "src/components/Typography";
import { violet300 } from "src/styles/variables.module.scss";

import * as styles from "./MakeItLoud.module.scss";

/*const query = graphql`
  query {
    allStrapiBlogs {
      edges {
        node {
          id
          title
          date
          slug
          image {
            localFile {
              publicURL
            }
          }
          blog_categories {
            id
            name
          }
        }
      }
    }
    allStrapiBlogCategories {
      edges {
        node {
          id
          name
        }
      }
    }
  }
`;*/
const query = graphql`
  query {
    allStrapiExternalLinks {
      edges {
        node {
          id
          Title
          Image {
            localFile {
              publicURL
            }
          }
          Link
        }
      }
    }
  }
`;

export const MakeItLoud: React.FC = () => {
  const ITEMS_COUNT = 2;

  const data = useStaticQuery(query);
  const blogs = data.allStrapiExternalLinks.edges;
  const [showedItems, setShowedItems] = useState(ITEMS_COUNT);

  const handleShowMoreClick = () => {
    setShowedItems((prev) => prev + ITEMS_COUNT);
  };

  return (
    <PageWrapper topAutoScroll title="Даем шума">
      <div>
        <Banner backgroundColor={violet300}>
          <Container>
            <Typography color="white" variant="h3">
              Даем шума
            </Typography>
          </Container>
          {/*<Container>
                        <LinkList
                            items={getLinksByBlogCategories(data, category)}
                            linkProps={{
                                color: 'white',
                                hoverFill: 'white',
                                size: 's',
                            }}
                            flexWrap
                        />
                    </Container>*/}
        </Banner>
        <Container>
          <div className={styles.wrapper}>
            {blogs.slice(0, showedItems).map((blog) => (
              <a
                href={blog.node.Link}
                key={blog.node.id}
                target="_blank"
                rel="noreferrer"
              >
                <div className={styles.card}>
                  <div
                    className={styles.image}
                    style={{
                      backgroundImage: `url(${blog.node.Image[0].localFile.publicURL})`,
                    }}
                  />

                  <div className={styles.headline}>{blog.node.Title}</div>
                  <Container>
                    <div className={styles.tag}>
                      {/*<LinkList
                                                items={
                                                    blog.node.blog_categories
                                                        ? blog.node.blog_categories.map((blogCategory) => ({
                                                            text: blogCategory.name,
                                                            href: createLink(PathEnum.Current, {
                                                                category: blogCategory.name,
                                                            }),
                                                            active: category === blogCategory.name,
                                                        }))
                                                        : []
                                                }
                                                linkProps={{
                                                    color: 'blue',
                                                    hoverFill: 'black',
                                                    size: 's',
                                                }}
                                                flexWrap
                                            />*/}
                    </div>
                  </Container>
                </div>
              </a>
            ))}
          </div>
        </Container>
        {showedItems < blogs.length && (
          <div className={styles.root}>
            <Button
              color="green"
              className={styles.button}
              onClick={handleShowMoreClick}
            >
              показать еще
            </Button>
          </div>
        )}
      </div>
    </PageWrapper>
  );
};
