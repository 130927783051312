import React from "react";

import { PageProps } from "gatsby";
import { ParamsProvider } from "src/components/ParamsProvider";
import { MakeItLoud } from "src/layouts/MakeItLoud";

const MakeItLoudPage: React.FC = ({ location }: PageProps) => {
  return (
    <ParamsProvider location={location}>
      <MakeItLoud />
    </ParamsProvider>
  );
};

export default MakeItLoudPage;
